<template>
    <div class="productWrap">
        <!-- <p class="productTitle">
            设备管理
        </p> -->
        <ul>
            <li style="display: flex; align-items: center">
                <a-select v-model="code" style="width: 200px; margin-right: 20px" @change="handleChange">
                    <a-select-option value="all">
                        全部产品
                    </a-select-option>
                    <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                        {{ item.productName }}
                    </a-select-option>
                </a-select>
                <ul class="equipList">
                    <li>
                        <p>设备总数</p>
                        <p class="equipNum">
                            {{ list.sumCount }}
                        </p>
                    </li>
                    <li>
                        <p>设备在线数</p>
                        <p class="equipNum">
                            {{ list.onlineCount }}
                        </p>
                    </li>
                    <li>
                        <p>设备激活数</p>
                        <p class="equipNum">
                            {{ list.offlineCount }}
                        </p>
                    </li>
                </ul>
            </li>
            <li style="display: flex; margin: 20px 0">
                <a-dropdown :trigger="['click']">
                    <a-button style="margin-left: 8px">
                        <a-icon type="setting" /> 自定义列表
                    </a-button>
                    <a-menu slot="overlay" style="padding: 20px; width: 400px">
                        <a-checkbox-group v-model="selectedList" @change="onChange">
                            <a-row>
                                <a-col :span="8">
                                    <a-checkbox value="code">
                                        设备code
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="productName">
                                        所属产品
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="status">
                                        设备状态
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="imei">
                                        IMEI
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="nodeType">
                                        节点类型
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="source">
                                        创建方式
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="createTime">
                                        添加时间
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="latestOnlineTime">
                                        最近在线时间
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </a-menu>
                </a-dropdown>
                <a-select v-model="equipStatus" style="width: 260px; margin: 0 20px">
                    <a-select-option value="3">
                        设备状态（全部）
                    </a-select-option>
                    <a-select-option value="0">
                        未激活
                    </a-select-option>
                    <a-select-option value="1">
                        在线
                    </a-select-option>
                    <a-select-option value="2">
                        离线
                    </a-select-option>
                </a-select>
                <a-select v-model="createMode" style="width: 200px;">
                    <a-select-option value="2">
                        创建方式（全部）
                    </a-select-option>
                    <a-select-option value="0">
                        人工注册
                    </a-select-option>
                    <a-select-option value="1">
                        自动注册
                    </a-select-option>
                </a-select>
                <a-input v-model="keyword" placeholder="请输入设备名称或id查询" style="width: 400px; margin: 0 20px" :allow-clear="true" />
                <a-button type="primary" @click="onSearch">
                    查询
                </a-button>
            </li>
            <li style="display: flex; flex-direction: row-reverse">
                <a-button type="primary" @click="visible = true">
                    添加设备
                </a-button>
                <a-button style="margin: 0 20px">
                    导出设备
                </a-button>
                <a-button @click="deleteEquipList">
                    批量删除
                </a-button>
            </li>
        </ul>
        <a-table style="margin-bottom: 20px"
                 :locale="{emptyText: '暂无数据'}"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                 table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
            <template slot="status" slot-scope="text, record">
                <span>{{ record.status | statusTypeFilter }}</span>
            </template>
            <template slot="nodeType" slot-scope="text, record">
                <span>{{ record.nodeType | nodeTypeFilter }}</span>
            </template>
            <template slot="source" slot-scope="text, record">
                <span>{{ record.source | sourceTypeFilter }}</span>
            </template>
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" @click="gotoDetails(record)">详情</a>
                <a href="javascript:void(0);" style="margin: 0 20px" @click="onEdit(record)">编辑</a>
                <a href="javascript:void(0);" @click="onDelete(record)">删除</a>
            </template>
        </a-table>
        <Pagination v-show="isShowPage" :current-page="page" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
        <!-- 添加设备弹窗 -->
        <a-modal
            :width="800"
            title="添加设备"
            :visible="visible"
            ok-text="确认"
            cancel-text="取消"
            @ok="onSubmit"
            @cancel="onCancel">
            <a-tabs type="card" @change="onTabs">
                <a-tab-pane key="1" class="card-container" tab="单个添加">
                    <a-form-model
                        ref="ruleForm"
                        :label-col="labelCol"
                        :wrapper-col="wrapperCol"
                        :model="form"
                        :rules="rules">
                        <a-form-model-item ref="name" label="设备code" prop="name">
                            <a-input
                                v-model="form.name"
                                placeholder="最长不超过32位，英文，数字及特殊字符" />
                        </a-form-model-item>
                        <a-form-model-item label="所属产品" prop="type">
                            <div style="display: flex">
                                <a-select v-model="form.type" placeholder="请选择产品类型">
                                    <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                                        {{ item.productName }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item label="设备描述" prop="desc">
                            <a-textarea
                                v-model="form.desc" />
                        </a-form-model-item>
                        <a-form-model-item>
                            <Map :is-show-site="false" :search-val="searchVal" @onSelect="chooseSite" />
                        </a-form-model-item>
                    </a-form-model>
                </a-tab-pane>
                <a-tab-pane key="2" tab="批量添加">
                    <ul>
                        <li class="addInfo">
                            <span>批量添加设备可在批次列表中查询相关记录<br>格式.xlsx 最大2M，单次500个设备</span>
                            <span class="downLoad" @click="downloadModel">模板下载</span>
                        </li>
                        <li style="margin: 30px 40px">
                            <a-upload
                                name="excelfile"
                                :action="uploadUrl"
                                :headers="headers"
                                :file-list="fileList"
                                :show-upload-list="{showRemoveIcon: false}"
                                :before-upload="beforeFileUpload"
                                :custom-request="customRequest">
                                <a-button>
                                    *上传设备表格
                                </a-button>
                            </a-upload>
                            <p v-if="fileCode !== 200" style="color: red">
                                {{ messageError }}
                            </p>
                        </li>
                        <li>
                            <a-form-model
                                ref="addRuleForm"
                                :label-col="labelCol"
                                :wrapper-col="wrapperCol"
                                :model="addForm"
                                :rules="addRules">
                                <a-form-model-item label="所属产品" prop="type">
                                    <div style="display: flex">
                                        <a-select v-model="addForm.type" placeholder="请选择产品类型">
                                            <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                                                {{ item.productName }}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                </a-form-model-item>
                            </a-form-model>
                        </li>
                    </ul>
                </a-tab-pane>
            </a-tabs>
        </a-modal>
        <!-- 编辑设备弹窗 -->
        <a-modal
            :width="800"
            title="编辑设备信息"
            :visible="editVisible"
            ok-text="确认"
            cancel-text="取消"
            @ok="onEditSubmit"
            @cancel="editVisible = false">
            <a-form-model
                ref="editRuleForm"
                :model="editForm">
                <a-form-model-item label="设备描述" prop="desc">
                    <a-textarea
                        v-model="editForm.desc" />
                </a-form-model-item>
                <a-form-model-item label="位置信息">
                    <Map :is-show-site="false" :search-val="editSearchVal" @onSelect="editChooseSite" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import Map from "@/components/Map"
import { getToken } from '@/utils/auth'
import { productList } from "@/api/product"
import { getEquipList, deleteEquip, changeEquip, searchEquip, deleteEquipList, addEquipList, fileRules} from "@/api/equipment"
import { validateRules } from "@/utils/validate"
import { setObjLocalStorage, getObjLocalStorage } from "@/utils/localstorage"
const columns = [
    {
        className: '',
        title: '设备code',
        dataIndex: 'code',
        key: 'id'
    },
    {
        className: '',
        title: '所属产品',
        dataIndex: 'productName',
        key: 'toProduct'
    },
    {
        className: '',
        title: '设备状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        className: '',
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'IMEI'
    },
    {
        className: '',
        title: '节点类型',
        dataIndex: 'nodeType',
        scopedSlots: { customRender: 'nodeType' }
    },
    {
        className: '',
        title: '创建方式',
        dataIndex: 'source',
        scopedSlots: { customRender: 'source' }
    },
    {
        className: '',
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'addTime'
    },
    {
        className: '',
        title: '最近在线时间',
        dataIndex: 'latestOnlineTime',
        key: 'onlineTime'
    },
    {
        className: '',
        title: '操作',
        width: '180px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
export default {
    name: "Index",
    components: {
        Pagination,
        Map
    },
    data() {
        return {
            // 自定义列表选中项
            selectedList: [],
            // 当前分页码
            page: 1,
            // 每页数据条数
            size: 10,
            equipStatus: '3',
            createMode: '2',
            columns,
            data: [],
            // 当前编辑的设备id
            equipId: '',
            // 当前编辑的设备code
            equipCode: '',
            productList: [],
            // 设备列表多选数据集合
            selectedRowKeys: [],
            // 当前编辑的设备所属产品code
            editProductCode: '',
            // 添加设备弹窗搜索的地址内容
            searchVal: '',
            // 编辑设备弹窗搜索的地址内容
            editSearchVal: '',
            // 设备数量集合
            list: {},
            // 所属产品code
            code: 'all',
            // 添加设备弹窗中选中的所属产品code
            toCode: '',
            // 添加设备弹窗中的地址
            site: {},
            // 编辑设备弹窗中的地址
            editSite: {},
            // 列表数据总条数
            total: 0,
            // 用户输入的查询关键字
            keyword: '',
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 添加设备的弹窗是否可见
            visible: false,
            // 编辑设备的弹窗是否可见
            editVisible: false,
            fileList: [],
            // 单个添加表单数据
            form: {
                name: '',
                type: undefined,
                desc: ''
            },
            // 编辑设备表单数据
            editForm: {
                desc: ''
            },
            // 单个添加表单验证规则
            rules: {
                name: [
                    { required: true, message: '请输入设备code', trigger: 'blur' },
                    { validator: validateRules, trigger: 'blur' },
                ],
                type: [{ required: true, message: '请选择产品', trigger: 'change' }]
            },
            // 批量添加表单数据
            addForm: {
                type: undefined
            },
            // 批量添加表单验证规则
            addRules: {
                type: [{ required: true, message: '请选择产品', trigger: 'change' }]
            },
            isShowPage: true,
            uploadUrl: 'http://appapi.paramland.cn/iotapi/manager/device/check',
            headers: {
                token: getToken()
            },
            // 当前批量添加设备弹窗中上传的文件接口校验编码
            fileCode: 0,
            // 当前tab切换key值
            onTabsKey: '1',
            // 文件校验失败的提示信息
            messageError: '',
            excelfile: {}
        }
    },
    created() {
        let autoList = getObjLocalStorage('list')
        if(autoList) {
            this.selectedList = autoList
            this.onAuto(autoList)
        } else {
            this.selectedList = ['code', 'productName', 'status', 'imei', 'nodeType', 'source', 'createTime', 'latestOnlineTime']
        }
        let id = this.$route.query.id || ''
        if(id) {
            this.code = id
        }
        this.visible = this.$route.query.fromPage !== undefined
        this.getProductList()
        this.getList(this.page, this.size, '', id)
    },
    methods: {
        customRequest(file) {
            const form = new FormData()
            form.append('excelfile', file.file)
            this.excelfile = form
            fileRules(form).then(res => {
                if(res.errorCode !== 200) {
                    this.messageError = res.errorMsg
                }
                this.fileCode = res.errorCode
                let fileList = [file.file]
                fileList = fileList.slice(-1)
                fileList = fileList.map(node => {
                    if (node.response) {
                        node.url = node.response.url
                    }
                    return node
                })
                this.fileList = fileList
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取产品列表数据
        getProductList() {
            productList().then(res => {
                this.productList = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取设备列表数据
        getList(page, size, name, code, statu, mode) {
            this.page = page
            getEquipList(page, size, name, code, statu, mode).then(res => {
                let info = res.data
                this.data = info.records
                this.total = info.total
                this.list = info.records[0] || { sumCount:0, onlineCount:0, offlineCount:0 }
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择分页
        changePage(page) {
            this.reloadList(this, page, this.size)
        },
        // 修改每页数据条数
        changeSize(size) {
            this.size = size
            this.reloadList(this, 1, size)
        },
        // 产品名称下拉选择
        handleChange() {
            this.keyword = ''
            this.reloadList(this, 1, this.size)
        },
        // 产品搜索
        onSearch() {
            this.reloadList(this, 1, this.size)
        },
        // 进入设备详情页
        gotoDetails(val) {
            this.$router.push(`/equip-list/details?id=${val.id}`)
        },
        // 编辑
        onEdit(val) {
            this.equipId = val.id
            this.equipCode = val.code
            searchEquip(val.id).then((res) => {
                let info = res.data
                this.editProductCode = info.productCode
                this.editSearchVal = info.address || ''
                this.editForm.desc = info.remark
                this.editSite = {
                    siteX: info.longitude || '',
                    siteY: info.latitude || '',
                    site: info.address || ''
                }
            }).catch(error => {
                console.log(error)
            })
            this.editVisible = true
        },
        // 选择编辑设备弹窗中的地址
        editChooseSite(val) {
            this.editSearchVal = val.title
            this.editSite = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 更新列表数据
        reloadList(that, page, size) {
            let status = parseInt(that.equipStatus),
                mode = parseInt(that.createMode),
                code = that.code !== 'all' ? that.code : ''
            status = status !== 3 ? status : ''
            mode = mode !== 2 ? mode : ''
            that.getList(page, size, that.keyword.trim(), code, status, mode)
        },
        // 删除
        onDelete(val) {
            this.$confirm({
                title: '设备删除',
                content: '确认删除该设备吗',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteEquip(val.id).then(() => {
                        this.$message.success('删除设备成功！')
                        this.reloadList(this, 1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        // 批量删除
        deleteEquipList() {
            if(this.selectedRowKeys.length > 0) {
                this.$confirm({
                    title: '设备删除',
                    content: '确认删除这些设备吗',
                    okText: '确定',
                    centered: true,
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        deleteEquipList(this.selectedRowKeys).then(() => {
                            this.$message.success('删除设备成功！')
                            this.reloadList(this, 1, this.size)
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                })
            } else {
                this.$message.warn('请先选择要删除的设备!')
            }
        },
        // 设备列表中的多选事件
        onSelectChange(val) {
            this.selectedRowKeys = val
        },
        // 选择添加设备弹窗中的地址
        chooseSite(val) {
            this.searchVal = val.title
            this.site = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 批量添加表单重置
        resetAddList() {
            this.fileList = []
            this.addForm.type = undefined
            this.messageError = ''
        },
        // 点击添加设备弹窗中的取消按钮
        onCancel() {
            this.visible = false
            if(this.onTabsKey === '2') {  // 批量添加
                this.resetAddList()
            }
        },
        // 点击添加设备弹窗中的确定按钮
        onSubmit() {
            if(this.onTabsKey === '1') {  // 单个添加
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        changeEquip(this.form.name.trim(), this.form.type, this.site.siteX, this.site.siteY, this.site.site, this.form.desc).then(() => {
                            this.$message.success('设备添加成功！')
                            this.visible = false
                            this.$refs.ruleForm.resetFields()
                            this.searchVal = ''
                            this.getProductList()
                            this.reloadList(this, 1, this.size)
                        }).catch(error => {
                            console.log(error)
                        })
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            } else {    // 批量添加
                if(this.fileList.length === 0) {
                    this.$message.warn('请先上传设备表格！')
                    return
                }
                this.$refs.addRuleForm.validate(valid => {
                    if (valid && this.fileCode === 200) {
                        addEquipList(this.addForm.type, this.excelfile).then(() => {
                            this.resetAddList()
                            this.getProductList()
                            this.reloadList(this, 1, this.size)
                            this.$message.success('上传成功！')
                            this.visible = false
                        }).catch(error => {
                            console.log(error)
                        })
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }
        },
        // 自定义列表操作
        onAuto(val) {
            let arr = [],
                arr1 = []
            val.forEach((node) => {
                this.columns.forEach((item, index) => {
                    if(item.dataIndex !== node) {
                        arr.push(index)
                    } else {
                        arr1.push(index)
                    }
                })
            })
            arr.forEach((item) => {
                if (item !== 9) {
                    this.columns[item].className = 'hidden'
                }
            })
            arr1.forEach((item) => {
                this.columns[item].className = ''
            })
            let flag = arr1.length === 0
            this.isShowPage = !flag
            if(flag) {
                this.columns.forEach((item) => {
                    item.className = 'hidden'
                })
            } else {
                this.columns.forEach((item) => {
                    if(item.title === '操作') {
                        item.className = ''
                    }
                })
            }
        },
        // 自定义列表中多选事件
        onChange(val) {
            setObjLocalStorage('list', val)
            this.onAuto(val)
        },
        // 模板下载操作
        downloadModel() {
            window.location.href = 'https://dcfile.paramland.com/upload_1a50b61a1477c6486f94a3594fcdbd6c.xlsx'
        },
        // tab切换操作
        onTabs(val) {
            this.onTabsKey = val
        },
        // 批量添加设备弹窗中的文件类型校验
        beforeFileUpload(file){
            let fileList = [file]
            fileList = fileList.slice(-1)
            fileList = fileList.map(node => {
                if (node.response) {
                    node.url = node.response.url
                }
                return node
            })
            this.fileList = fileList
            let fileName = file.name
            let pos = fileName.lastIndexOf(".")
            let lastName = fileName.substring(pos, fileName.length)
            if (
                lastName.toLowerCase() !== ".xlsx"
            ) {
                this.fileCode = 0
                this.messageError = "文件格式不正确，请上传xlsx文件。"
                return false
            }
        },
        // 点击编辑设备弹窗中的确定按钮
        onEditSubmit() {
            this.$refs.editRuleForm.validate(valid => {
                if (valid) {
                    changeEquip(this.equipCode, '', this.editSite.siteX, this.editSite.siteY, this.editSite.site, this.editForm.desc, this.equipId).then(() => {
                        this.$message.success('设备编辑成功！')
                        this.editVisible = false
                        this.reloadList(this, 1, this.size)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .hidden{
        display: none;
    }
    .productWrap {
        margin: 20px 40px;
        .productTitle {
            font-size: 20px;
            font-weight: bold;
        }
    }
    ::v-deep .ant-pagination {
        text-align: right;
    }
    ::v-deep .ant-pagination-total-text {
        float: left;
    }
    ::v-deep .ant-table-thead > tr > th,
    ::v-deep .ant-table-tbody > tr > td {
        padding: 16px 10px;
    }
    .addInfo {
        display: flex;
        align-items: center;
        margin-left: 40px;
        .downLoad {
            color: #1890ff;
            margin-left: 30px;
            cursor: pointer;
        }
    }
    .card-container {
        background: #f5f5f5;
        overflow: hidden;
        padding: 24px;
    }
    .card-container > .ant-tabs-card > .ant-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane > .ant-table-wrapper{
        background: #fff;
        padding: 16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar {
        border-color: #fff;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
    }
    .equipList {
        display: flex;
        li {
            margin-left: 60px;
            text-align: center;
        }
        p {
            margin-bottom: 0;
        }
        .equipNum {
            font-size: 16px;
            font-weight: bold;
            margin-top: 14px;
        }
    }
    ::v-deep .ant-table-body colgroup col {
        width: auto !important;
    }
</style>
