var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c("ul", [
        _c(
          "li",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "200px", "margin-right": "20px" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              },
              [
                _c("a-select-option", { attrs: { value: "all" } }, [
                  _vm._v(" 全部产品 "),
                ]),
                _vm._l(_vm.productList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.code } },
                    [_vm._v(" " + _vm._s(item.productName) + " ")]
                  )
                }),
              ],
              2
            ),
            _c("ul", { staticClass: "equipList" }, [
              _c("li", [
                _c("p", [_vm._v("设备总数")]),
                _c("p", { staticClass: "equipNum" }, [
                  _vm._v(" " + _vm._s(_vm.list.sumCount) + " "),
                ]),
              ]),
              _c("li", [
                _c("p", [_vm._v("设备在线数")]),
                _c("p", { staticClass: "equipNum" }, [
                  _vm._v(" " + _vm._s(_vm.list.onlineCount) + " "),
                ]),
              ]),
              _c("li", [
                _c("p", [_vm._v("设备激活数")]),
                _c("p", { staticClass: "equipNum" }, [
                  _vm._v(" " + _vm._s(_vm.list.offlineCount) + " "),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticStyle: { display: "flex", margin: "20px 0" } },
          [
            _c(
              "a-dropdown",
              { attrs: { trigger: ["click"] } },
              [
                _c(
                  "a-button",
                  { staticStyle: { "margin-left": "8px" } },
                  [
                    _c("a-icon", { attrs: { type: "setting" } }),
                    _vm._v(" 自定义列表 "),
                  ],
                  1
                ),
                _c(
                  "a-menu",
                  {
                    staticStyle: { padding: "20px", width: "400px" },
                    attrs: { slot: "overlay" },
                    slot: "overlay",
                  },
                  [
                    _c(
                      "a-checkbox-group",
                      {
                        on: { change: _vm.onChange },
                        model: {
                          value: _vm.selectedList,
                          callback: function ($$v) {
                            _vm.selectedList = $$v
                          },
                          expression: "selectedList",
                        },
                      },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c("a-checkbox", { attrs: { value: "code" } }, [
                                  _vm._v(" 设备code "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "productName" } },
                                  [_vm._v(" 所属产品 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "status" } },
                                  [_vm._v(" 设备状态 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c("a-checkbox", { attrs: { value: "imei" } }, [
                                  _vm._v(" IMEI "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "nodeType" } },
                                  [_vm._v(" 节点类型 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "source" } },
                                  [_vm._v(" 创建方式 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "createTime" } },
                                  [_vm._v(" 添加时间 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "latestOnlineTime" } },
                                  [_vm._v(" 最近在线时间 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "260px", margin: "0 20px" },
                model: {
                  value: _vm.equipStatus,
                  callback: function ($$v) {
                    _vm.equipStatus = $$v
                  },
                  expression: "equipStatus",
                },
              },
              [
                _c("a-select-option", { attrs: { value: "3" } }, [
                  _vm._v(" 设备状态（全部） "),
                ]),
                _c("a-select-option", { attrs: { value: "0" } }, [
                  _vm._v(" 未激活 "),
                ]),
                _c("a-select-option", { attrs: { value: "1" } }, [
                  _vm._v(" 在线 "),
                ]),
                _c("a-select-option", { attrs: { value: "2" } }, [
                  _vm._v(" 离线 "),
                ]),
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "200px" },
                model: {
                  value: _vm.createMode,
                  callback: function ($$v) {
                    _vm.createMode = $$v
                  },
                  expression: "createMode",
                },
              },
              [
                _c("a-select-option", { attrs: { value: "2" } }, [
                  _vm._v(" 创建方式（全部） "),
                ]),
                _c("a-select-option", { attrs: { value: "0" } }, [
                  _vm._v(" 人工注册 "),
                ]),
                _c("a-select-option", { attrs: { value: "1" } }, [
                  _vm._v(" 自动注册 "),
                ]),
              ],
              1
            ),
            _c("a-input", {
              staticStyle: { width: "400px", margin: "0 20px" },
              attrs: {
                placeholder: "请输入设备名称或id查询",
                "allow-clear": true,
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            }),
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSearch } },
              [_vm._v(" 查询 ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticStyle: { display: "flex", "flex-direction": "row-reverse" } },
          [
            _c(
              "a-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.visible = true
                  },
                },
              },
              [_vm._v(" 添加设备 ")]
            ),
            _c("a-button", { staticStyle: { margin: "0 20px" } }, [
              _vm._v(" 导出设备 "),
            ]),
            _c("a-button", { on: { click: _vm.deleteEquipList } }, [
              _vm._v(" 批量删除 "),
            ]),
          ],
          1
        ),
      ]),
      _c("a-table", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          locale: { emptyText: "暂无数据" },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          "table-layout": "auto",
          columns: _vm.columns,
          pagination: false,
          "row-key": "id",
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("statusTypeFilter")(record.status))),
                ]),
              ]
            },
          },
          {
            key: "nodeType",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("nodeTypeFilter")(record.nodeType))),
                ]),
              ]
            },
          },
          {
            key: "source",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("sourceTypeFilter")(record.source))),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                _c(
                  "a",
                  {
                    staticStyle: { margin: "0 20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onEdit(record)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(record)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowPage,
            expression: "isShowPage",
          },
        ],
        attrs: { "current-page": _vm.page, count: _vm.total },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "添加设备",
            visible: _vm.visible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: { ok: _vm.onSubmit, cancel: _vm.onCancel },
        },
        [
          _c(
            "a-tabs",
            { attrs: { type: "card" }, on: { change: _vm.onTabs } },
            [
              _c(
                "a-tab-pane",
                {
                  key: "1",
                  staticClass: "card-container",
                  attrs: { tab: "单个添加" },
                },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                        model: _vm.form,
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "name",
                          attrs: { label: "设备code", prop: "name" },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder:
                                "最长不超过32位，英文，数字及特殊字符",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "所属产品", prop: "type" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: { placeholder: "请选择产品类型" },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type",
                                  },
                                },
                                _vm._l(_vm.productList, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.id,
                                      attrs: { value: item.code },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.productName) + " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "设备描述", prop: "desc" } },
                        [
                          _c("a-textarea", {
                            model: {
                              value: _vm.form.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "desc", $$v)
                              },
                              expression: "form.desc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        [
                          _c("Map", {
                            attrs: {
                              "is-show-site": false,
                              "search-val": _vm.searchVal,
                            },
                            on: { onSelect: _vm.chooseSite },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-tab-pane", { key: "2", attrs: { tab: "批量添加" } }, [
                _c("ul", [
                  _c("li", { staticClass: "addInfo" }, [
                    _c("span", [
                      _vm._v("批量添加设备可在批次列表中查询相关记录"),
                      _c("br"),
                      _vm._v("格式.xlsx 最大2M，单次500个设备"),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "downLoad",
                        on: { click: _vm.downloadModel },
                      },
                      [_vm._v("模板下载")]
                    ),
                  ]),
                  _c(
                    "li",
                    { staticStyle: { margin: "30px 40px" } },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            name: "excelfile",
                            action: _vm.uploadUrl,
                            headers: _vm.headers,
                            "file-list": _vm.fileList,
                            "show-upload-list": { showRemoveIcon: false },
                            "before-upload": _vm.beforeFileUpload,
                            "custom-request": _vm.customRequest,
                          },
                        },
                        [_c("a-button", [_vm._v(" *上传设备表格 ")])],
                        1
                      ),
                      _vm.fileCode !== 200
                        ? _c("p", { staticStyle: { color: "red" } }, [
                            _vm._v(" " + _vm._s(_vm.messageError) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "a-form-model",
                        {
                          ref: "addRuleForm",
                          attrs: {
                            "label-col": _vm.labelCol,
                            "wrapper-col": _vm.wrapperCol,
                            model: _vm.addForm,
                            rules: _vm.addRules,
                          },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "所属产品", prop: "type" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: { placeholder: "请选择产品类型" },
                                      model: {
                                        value: _vm.addForm.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.addForm, "type", $$v)
                                        },
                                        expression: "addForm.type",
                                      },
                                    },
                                    _vm._l(_vm.productList, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.id,
                                          attrs: { value: item.code },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.productName) + " "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "编辑设备信息",
            visible: _vm.editVisible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.onEditSubmit,
            cancel: function ($event) {
              _vm.editVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "editRuleForm", attrs: { model: _vm.editForm } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "设备描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.editForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "desc", $$v)
                      },
                      expression: "editForm.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "位置信息" } },
                [
                  _c("Map", {
                    attrs: {
                      "is-show-site": false,
                      "search-val": _vm.editSearchVal,
                    },
                    on: { onSelect: _vm.editChooseSite },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }